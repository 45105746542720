<script setup lang="ts">
import { useCurrentOrganization, useCurrentOrganizationId, useCurrentUserOrganizations } from '~/src/shared/lib/current-user';

const currentOrganizationId = await useCurrentOrganizationId();
const [currentOrganization, currentUserOrganizations] = await Promise.all([useCurrentOrganization(), useCurrentUserOrganizations()]);

function onChangeOrg() {
  currentOrganizationId.value = currentOrganization.value!.id;
};
</script>

<template>
  <PDropdown v-model="currentOrganization" :options="currentUserOrganizations" placeholder="Select organization" @change="onChangeOrg">
    <template #value="{ value: organization, placeholder }">
      <template v-if="organization">
        {{ organization.name }}
      </template>
      <template v-else>
        {{ placeholder }}
      </template>
    </template>
    <template #option="{ option: organization }">
      {{ organization.name }}
    </template>
  </PDropdown>
</template>
