<script setup lang="ts">
import {
  Bars3Icon,
  BellIcon,
  BriefcaseIcon,
  ClipboardDocumentIcon,
  Cog6ToothIcon,
  HomeIcon,
} from '@heroicons/vue/24/outline';
import POverlayPanel from 'primevue/overlaypanel';
import { OrganizationSelector } from '~/src/features/org-selection';
// import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';

const navigation = [
  { name: 'Dashboard', href: '/app', icon: HomeIcon },
  { name: 'Book resources', href: '/app/book', icon: BriefcaseIcon },
  { name: 'Your bookings', href: '/app/bookings', icon: ClipboardDocumentIcon },
];
const userNavigation = [
  { name: 'My profile', href: '/app/my-profile' },
  { name: 'Sign out', href: '/sign-out', external: true },
];

const sidebarOpen = ref(false);

const avatarMenuRef = ref<InstanceType<typeof POverlayPanel>>();
function showAvatarMenu(event: Event) {
  avatarMenuRef.value?.toggle(event);
}
</script>

<template>
  <div>
    <PSidebar v-model:visible="sidebarOpen" position="left">
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-4">
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <NuxtLink
                    :to="item.href"
                    class="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    :class="[$route.path === item.href ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50']"
                    @click="sidebarOpen = false"
                  >
                    <component
                      :is="item.icon" class="h-6 w-6 shrink-0"
                      :class="[$route.path === item.href ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600']"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li>
              <div class="text-xs font-semibold leading-6 text-gray-400 mb-2">
                Organization
              </div>
              <OrganizationSelector class="w-full" />
            </li>
            <li class="mt-auto">
              <NuxtLink
                to="#"
                class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                @click="sidebarOpen = false"
              >
                <Cog6ToothIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600" aria-hidden="true" />
                Settings
              </NuxtLink>
            </li>
          </ul>
        </nav>
      </div>
    </PSidebar>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
        <div class="flex h-16 shrink-0 items-center">
          <img class="h-8 w-auto" src="https://devifex-assets.s3.eu-central-1.amazonaws.com/Logo.svg" alt="Your Company">
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <NuxtLink :to="item.href" class="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold" :class="[$route.path === item.href ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50']">
                    <component :is="item.icon" class="h-6 w-6 shrink-0" :class="[$route.path === item.href ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600']" aria-hidden="true" />
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li>
              <div class="text-xs font-semibold leading-6 text-gray-400 mb-2">
                Organization
              </div>
              <OrganizationSelector class="w-full" />
            </li>
            <li class="mt-auto">
              <NuxtLink to="#" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                <Cog6ToothIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600" aria-hidden="true" />
                Settings
              </NuxtLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="lg:pl-72">
      <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <!-- <form class="relative flex flex-1" action="#" method="GET">
            <label for="search-field" class="sr-only">Search</label>
            <MagnifyingGlassIcon class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" aria-hidden="true" />
            <input id="search-field" class="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Search..." type="search" name="search">
          </form> -->
          <div class="flex-grow" />
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>

            <!-- Separator -->
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

            <!-- Profile dropdown -->
            <PAvatar
              size="large" shape="circle"
              image="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              class="cursor-pointer"
              @click="showAvatarMenu"
            />
            <POverlayPanel
              ref="avatarMenuRef"
            >
              <PMenu :model="userNavigation" class="border-none">
                <template #item="{ item }">
                  <NuxtLink :to="item.href" :external="item.external" class="block p-2">
                    {{ item.name }}
                  </NuxtLink>
                </template>
              </PMenu>
            </POverlayPanel>
          </div>
        </div>
      </div>

      <main class="py-10">
        <div class="px-4 sm:px-6 lg:px-8">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
